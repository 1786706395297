// extracted by mini-css-extract-plugin
export var projectsBlock = "styles-module--projects-block--Ps8bS";
export var projectsBlockTitle = "styles-module--projects-block-title--tGHFM";
export var projectsBlockWrapper = "styles-module--projects-block-wrapper--T+fWy";
export var projectsBlockImage = "styles-module--projects-block-image--g6pB3";
export var projectsBlockImageChanged = "styles-module--projects-block-image-changed--wQoZs";
export var imageChangedDesktop = "styles-module--imageChangedDesktop--zdJ8t";
export var projectsBlockImageActive = "styles-module--projects-block-image-active--ywYGU";
export var projectsBlockForImage = "styles-module--projects-block-for-image--3--6x";
export var projectsBlockForImageActive = "styles-module--projects-block-for-image-active--zpGJv";
export var projectsBlockInfo = "styles-module--projects-block-info--gDl2Y";
export var projectsBlockInfoMini = "styles-module--projects-block-info-mini--l51IY";
export var projectsBlockSubtitle = "styles-module--projects-block-subtitle--YBqlB";
export var justifyContentRight = "styles-module--justifyContentRight--dj9rL";