import React from 'react'
import Lottie from 'react-lottie'

import * as styles from './styles.module.scss'

import * as animationData from './animate.json'

const Sphere = ({ isPlay, coordinates }) => {
  const defaultOptions = {
    loop: true,
    autoplay: isPlay,
    animationData: animationData,
    duration: 1000,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <div
      className={styles.sphere}
      style={{
        transform: `translate(-${coordinates.x * 0.04}px, -${
          coordinates.y * 0.04
        }px)`,
        transition: '1s',
        pointerEvents: 'none',
      }}
    >
      <Lottie
        options={defaultOptions}
        height={484}
        width={484}
        speed={isPlay ? 1 : 0}
      />
    </div>
  )
}

export default Sphere
