// extracted by mini-css-extract-plugin
export var caseBlock = "styles-module--case-block--ZXC7k";
export var caseBlockTitle = "styles-module--case-block-title--Z9lgh";
export var caseBlockCards = "styles-module--case-block-cards--CaTng";
export var caseBlockCard = "styles-module--case-block-card--enE5x";
export var fadeOutBlock = "styles-module--fadeOutBlock--q-CJO";
export var fadeOutImage = "styles-module--fadeOutImage--n9L7z";
export var fadeOutScale = "styles-module--fadeOutScale--X2Ak1";
export var caseBlockCardActive = "styles-module--case-block-card-active--aIi8+";
export var fadeBlock = "styles-module--fadeBlock--nm1rx";
export var fadeImage = "styles-module--fadeImage--CtHHr";
export var fadeInScale = "styles-module--fadeInScale--cVoQj";
export var caseLink = "styles-module--caseLink--bi6IO";