// extracted by mini-css-extract-plugin
export var industriesBlock = "styles-module--industries-block--yhaAk";
export var industriesBlockContainer = "styles-module--industries-block-container--q90CZ";
export var industriesBlockLink = "styles-module--industries-block-link--AWF-F";
export var industriesBlockTop = "styles-module--industries-block-top--kcqpb";
export var industriesBlockTitle = "styles-module--industries-block-title--7kMiH";
export var industriesBlockCards = "styles-module--industries-block-cards--dztK6";
export var industriesBlockWrap = "styles-module--industries-block-wrap--JWPgC";
export var industriesBlockIcon = "styles-module--industries-block-icon--mD6Db";
export var industriesBlockCard = "styles-module--industries-block-card--j82BE";
export var industriesBlockTopLink = "styles-module--industries-block-top-Link--eTrbR";