import React from 'react'

import '../../assets/css/fonts.css'

import MainBlock from './MainBlock/MainBlock'
import SolutionBlock from './SolutionBlock/SolutionBlock'
import IndustriesBlock from './IndustriesBlock/IndustriesBlock'
import CompanyBlock from './CompanyBlock/CompanyBlock'
import CaseBlock from './CaseBlock/CaseBlock'
import ProjectsBlock from './ProjectsBlock/ProjectsBlock'
import PartnersBlock from './PartnersBlock/PartnersBlock'
import CustomersBlock from './CustomersBlock/CustomersBlock'
import FormBlock from './FormBlock/FormBlock'
import OurAwards from './OurAwards/OurAwards'

const MainPage = () => (
  <div>
    <MainBlock />
    <SolutionBlock />
    <IndustriesBlock />
    <CompanyBlock />
    <CaseBlock />
    <ProjectsBlock />
    <PartnersBlock />
    <CustomersBlock />
    <OurAwards />
    <FormBlock />
  </div>
)

export default MainPage
