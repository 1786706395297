import React, { useCallback, useState } from 'react'
import { Link } from 'gatsby'

import { LinkButton } from '../../sharedComponents/buttons/Buttons'

import * as styles from './styles.module.scss'

const Card = ({ type, title, link, src, className, ...props }) => (
  <div className={className} {...props}>
    <span>{type}</span>
    <h3>{title}</h3>
    <Link to={link} className={styles.caseLink}>
      <LinkButton>Get started</LinkButton>
    </Link>
    <img src={src} alt={title} />
  </div>
)

const CaseBlockCards = ({ cardsArr, active }) => {
  const [isActive, setIsActive] = useState(true)
  const windowResolution =
    typeof window !== 'undefined'
    && window.innerWidth < 1201

  const handleMouseMove = useCallback((e, id, flag) => {
    e.preventDefault()
  if (windowResolution) return
  if (id !== active) setIsActive(flag)
},[])

  return (
    <div className={styles.caseBlockCards}>
      {cardsArr.map(({ id, ...card }) =>
          <Card
            className={
              (isActive && id === active) || (!isActive && id !== active)
                ? `${styles.caseBlockCard} ${styles.caseBlockCardActive}`
                : styles.caseBlockCard
            }
            onMouseEnter={(e) => handleMouseMove(e, id, false)}
            onMouseLeave={(e) => handleMouseMove(e, id, true)}
            key={id}
            id={id}
            {...card}
          />
      )}
    </div>
  )
}

export default CaseBlockCards
