// extracted by mini-css-extract-plugin
export var mainBlock = "styles-module--main-block--i6wi8";
export var mainBlockWrapper = "styles-module--main-block-wrapper--gsCOh";
export var mainBlockContainer = "styles-module--main-block-container--m9NVi";
export var mainBlockImageTop = "styles-module--main-block-image-top--lAyw8";
export var mainBlockPlusTop = "styles-module--main-block-plus-top--kD7q6";
export var mainBlockTitle = "styles-module--main-block-title--zEloc";
export var mainBlockSubtitle = "styles-module--main-block-subtitle--z-L-F";
export var mainBlockContactLink = "styles-module--main-block-contact-link--WQBKJ";
export var mainBlockAlert = "styles-module--main-block-alert--+y2x0";
export var mainBlockAlertText = "styles-module--main-block-alert-text--eQAZr";
export var mainBlockImageBottom = "styles-module--main-block-image-bottom--cS3Dw";
export var mainBlockPlusBottom = "styles-module--main-block-plus-bottom--v4OxL";
export var mainBlockSphere = "styles-module--main-block-sphere--xfI9q";
export var sphere = "styles-module--sphere--X8uZL";