import React from 'react'
import { Link } from 'gatsby'

import { MainButton } from '../../sharedComponents/buttons/Buttons'
import Title from '../../sharedComponents/Title/Title'

import * as styles from './styles.module.scss'

import icon1 from '../../../assets/images/svg/industriesBlock/ind icon 1.svg'
import icon2 from '../../../assets/images/svg/industriesBlock/ind icon 2.svg'
import icon3 from '../../../assets/images/svg/industriesBlock/ind icon 3.svg'
import icon4 from '../../../assets/images/svg/industriesBlock/ind icon 4.svg'
import icon5 from '../../../assets/images/svg/industriesBlock/ind icon 5.svg'

const cardsArr = [
  {
    id: 1,
    src: icon1,
    title: 'Fintech',
    link: '/industries/fintech/',
    list: [
      {
        id: 1,
        text: 'Peer to peer platforms',
      },
      {
        id: 2,
        text: 'Personal finances tools',
      },
      {
        id: 3,
        text: 'Trading platforms',
      },
      {
        id: 4,
        text: 'Financial analytics',
      },
    ],
  },
  {
    id: 2,
    src: icon2,
    title: 'E-commerce',
    link: '/industries/e-commerce/',
    list: [
      {
        id: 1,
        text: 'Cloud-based enterprise systems',
      },
      {
        id: 2,
        text: 'Enterprise animations',
      },
      {
        id: 3,
        text: 'Fleet management',
      },
      {
        id: 4,
        text: 'Connectivity, visibility',
      },
    ],
  },
  {
    id: 3,
    src: icon3,
    title: 'Healthcare',
    link: '/industries/healthcare/',
    list: [
      {
        id: 1,
        text: 'Digital applications for hospital staff',
      },
      {
        id: 2,
        text: 'Personal finances tools',
      },
      {
        id: 3,
        text: 'Trading platforms',
      },
      {
        id: 4,
        text: 'Financial analytics',
      },
    ],
  },
  {
    id: 4,
    src: icon4,
    title: 'Renewable resources',
    link: '/industries/renewable-resources/',
    list: [
      {
        id: 1,
        text: 'Predictive analysis',
      },
      {
        id: 2,
        text: 'Distributed energy',
      },
      {
        id: 3,
        text: 'Resources Management system',
      },
      {
        id: 4,
        text: 'CRM & ERP software',
      },
    ],
  },
  {
    id: 5,
    src: icon5,
    title: 'Agrotech',
    link: '/industries/agrotech/',
    list: [
      {
        id: 1,
        text: 'Peer to peer platforms',
      },
      {
        id: 2,
        text: 'Personal finances tools',
      },
      {
        id: 3,
        text: 'Trading platforms',
      },
      {
        id: 4,
        text: 'Financial analytics',
      },
    ],
  },
]

const Card = ({ src, title, list, link }) => (
  <div className={styles.industriesBlockWrap}>
    <div className={styles.industriesBlockCard}>
      <div className={styles.industriesBlockTop}>
        <div className={styles.industriesBlockIcon}>
          <img src={src} alt={title} loading="lazy" />
        </div>
        <h3>{title}</h3>
        <div className={styles.industriesBlockTopLink}>
          <Link to={link}>
            <span>get started</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="16"
              viewBox="0 0 19 16"
              fill="none"
            >
              <path
                d="M18.7071 8.70711C19.0976 8.31658 19.0976 7.68342 18.7071 7.29289L12.3431 0.928932C11.9526 0.538408 11.3195 0.538408 10.9289 0.928932C10.5384 1.31946 10.5384 1.95262 10.9289 2.34315L16.5858 8L10.9289 13.6569C10.5384 14.0474 10.5384 14.6805 10.9289 15.0711C11.3195 15.4616 11.9526 15.4616 12.3431 15.0711L18.7071 8.70711ZM0 9L18 9V7L0 7V9Z"
                fill="#3978F4"
              />
            </svg>
          </Link>
        </div>
      </div>
      <ul>
        {list.map(({ id, text }) => (
          <li key={id}>{text}</li>
        ))}
      </ul>
      <Link to={link}>
        <MainButton className={styles.industriesBlockLink}>
          Get started
        </MainButton>
      </Link>
    </div>
  </div>
)

const IndustriesBlock = () => (
  <div id="industries" className={styles.industriesBlock}>
    <div className={`${styles.industriesBlockContainer} container`}>
      <Title className={styles.industriesBlockTitle}>
        Industries of proven expertise
      </Title>
      <div className={styles.industriesBlockCards}>
        {cardsArr.map(({ id, ...obj }) =>
          <Card {...obj} key={id} />
        )}
      </div>
    </div>
  </div>
)

export default IndustriesBlock
