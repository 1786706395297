import React, { useRef } from 'react'
import Slider from 'react-slick'

import Title from '../../sharedComponents/Title/Title'
import { ArrowButton } from '../../sharedComponents/buttons/Buttons'

import * as styles from './styles.module.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import swapnilkole from '../../../assets/images/jpg/Swapnil Kole-min.jpeg'
import yliyadachenkova from '../../../assets/images/jpg/Yuliya Dachenkova.jpeg'
import viktorEfremov from '../../../assets/images/jpg/Viktor Efremov-min.jpeg'
import dmitryGarbar from '../../../assets/images/jpg/Dmitry Garbar.jpeg'
import davidStephen from '../../../assets/images/jpg/David Stephen-min.jpeg'
import atiyahQadri from '../../../assets/images/jpg/Atiyah Qadri.jpg'
import maximNovitsky from '../../../assets/images/jpg/Maxim Novitsky-min.jpeg'

const cardsArr = [
  {
    id: 1,
    title: 'Professional approach.',
    text:
      'The services provided to us were very helpful, timely, and accurate. We appreciate it really well.\n' +
      'We will be approaching again for future services also.',
    name: 'Swapnil Kole',
    post: 'TA at II of IT',
    src: swapnilkole,
  },
  {
    id: 2,
    title: 'Justice IT is our ideal IT partner.',
    text:
      "We worked with Justice IT for more than 6 months. Our clients were completely satisfied. They aim to provide the best services and to be very responsible for our client's needs.\n" +
      'Justice IT is a reliable partner who you can turn to any time for professional Frontend development in FinTech, especially NFT market. Was great experience working with Justice IT, and we are looking forward for new cooperations!)',
    name: 'Yuliya Dachenkova',
    post: 'BMM of AOG.jobs',
    src: yliyadachenkova,
  },
  {
    id: 3,
    title: 'Very professional.',
    text:
      'Great experience with a dedicated team. Very professional and high-quality work of specialists. I would like to note the efficiency of solving problems and the high customer focus of the company.',
    name: 'Viktor Efremov',
    post: 'PM BB LLC',
    src: viktorEfremov,
  },
  {
    id: 4,
    title: 'Company of good professionals in web development and business processes',
    text:
      'During our cooperation, Justice IT has shown itself to be an executive team that copes with complex tasks. The guys quickly cope with tasks, show themselves as a client-oriented team. We will be glad to cooperate in the future!',
    name: 'Dmitry Garbar',
    post: 'Own HIPAA/GDPR Solution',
    src: dmitryGarbar,
  },
  {
    id: 5,
    title: 'Team for effective cooperation',
    text:
      'We have already cooperated with Justice IT on several projects, mainly in the FinTech sphere. The team shows its well-coordinated work, customer focus and focus on the best result. Tasks are completed on time, communication is at a good level. We plan to work together further.',
    name: 'Atiyah Qadri',
    post: 'Sales Specialist at Smartech LLC',
    src: atiyahQadri,
  },
  {
    id: 6,
    title: 'Finished product in a week.',
    text:
      'Thanks to the guys from Justice IT for the work done. Very professional and high-quality work of specialists. Having turned to them only with an idea, I received a finished product in a week.',
    name: 'Maxim Novitsky',
    post: 'Owner of MDM',
    src: maximNovitsky,
  },
  {
    id: 7,
    title: 'Well-established teamwork.',
    text:
      '“Justice IT” team completed a large amount of work in a short time. Well-established teamwork and competent distribution of responsibilities gave a positive result.',
    name: 'David Stephen',
    post: 'member of Atelos',
    src: davidStephen,
  },
]

const CustomPadding = () => <span />
const settings = {
  className: 'center',
  centerMode: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  arrows: false,
  customPaging: CustomPadding,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        className: 'center',
        centerMode: false,
      },
    },
  ],
}

const Card = ({ title, text, name, post, src }) => (
  <div className={styles.customersBlockCard}>
    <h3>{title}</h3>
    <p>{text}</p>
    <div className={styles.customersBlockCardBottom}>
      <img src={src} alt="user-image" loading="lazy" />
      <div className={styles.customersBlockCardUser}>
        <p>{name}</p>
        <p>{post}</p>
      </div>
    </div>
  </div>
)

const CustomersBlock = () => {
  const sliderRef = useRef()

  return (
    <div className={`${styles.customersBlock} customer-block`}>
      <div className={`${styles.customersBlockContainer} container`}>
        <div className={styles.customersBlockText}>
          <p className={styles.customersBlockSubtitle}>What our</p>
          <Title className={styles.customersBlockTitle}>
            Сustomers are
            <span>saying</span>
          </Title>
          <div className={styles.customersBlockTextButtons}>
            <ArrowButton
              aria-label="arrow-prev"
              onClick={() => sliderRef.current.slickPrev()}
            />
            <ArrowButton
              aria-label="arrow-next"
              onClick={() => sliderRef.current.slickNext()}
            />
          </div>
        </div>
        <div className={styles.customersBlockCards}>
          <Slider ref={sliderRef} {...settings}>
            {cardsArr.map(({ id, ...obj }) => (
              <Card key={id} {...obj} />
            ))}
          </Slider>
        </div>
        <div className={styles.customersBlockButtons}>
          <ArrowButton onClick={() => sliderRef.current.slickPrev()} />
          <ArrowButton onClick={() => sliderRef.current.slickNext()} />
        </div>
      </div>
    </div>
  )
}

export default CustomersBlock
