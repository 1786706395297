import React from 'react'
import Lottie from 'react-lottie'

import * as animationData from './data.json'

const ContactUs = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    duration: 1000,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return <Lottie options={defaultOptions} width={109} height={109} speed={1} />
}

export default ContactUs
