import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from 'react'
import { Link } from 'gatsby'

import Title from '../../sharedComponents/Title/Title'
import { MainButton } from '../../sharedComponents/buttons/Buttons'

import * as styles from './styles.module.scss'

import image from '../../../assets/images/png/projectsBlock/NFT 1.png'
import image2 from '../../../assets/images/png/projectsBlock/crypto.png'
import image3 from '../../../assets/images/png/projectsBlock/bank atm.png'
import image4 from '../../../assets/images/png/projectsBlock/img4.png'
import image5 from '../../../assets/images/png/projectsBlock/x2.png'

const imageArray = [
  {
    img: image,
    title: 'NFT Market',
    text: 'Monetize your creation of art',
    chips: 'React, Redux, Web3, AWS',
    link: '/cases/nft-market/',
    linkText: 'View NFT Market',
    color: '#FAEBE3',
  },
  {
    img: image2,
    title: 'Exchange',
    text: 'Blockchain comes alive in your hands',
    chips: 'WebView, React, Redux, Web3',
    link: '/cases/exchange/',
    linkText: 'View Exchange',
    color: '#E3ECFA',
  },
  {
    img: image5,
    title: 'Health Care',
    text: 'Your medical card in one QR-code',
    chips: 'React.js, Reatom, Typescript',
    link: '/health-care/',
    linkText: 'View Health Care',
    color: '#000',
  },
  // {
  //   img: image3,
  //   title: 'Bank ATM location and Virtual assistant.',
  //   chips: 'WebView, React, Redux, Firebase, React Yandex Map',
  //   link: '/cases/virtual-assistant/',
  //   linkText: 'View Bank assistant',
  //   color: '#EEF6FA',
  // },
  {
    img: image4,
    title: 'Renewable energy',
    text: 'Largest producer of wind & solar energy',
    chips: 'React.js, Redux, Typescript',
    link: '/cases/renewable-energy/',
    linkText: 'View renewable energy',
    color: '#ECECEC',
  },
  // {
  //   img: image5,
  //   title: 'Health Care - your medical card in one QR-code',
  //   chips: 'React.js, Reatom, Typescript',
  //   link: '/health-care/',
  //   linkText: 'View Health Care',
  //   color: '#000',
  // },
]

const mappedArray = [
  {
    id: 0,
    position: 'relative',
  },
  {
    id: 1,
    position: false,
  },
  {
    id: 2,
    position: 'relative',
  },
  {
    id: 3,
    position: 'relative',
  },
  // {
  //   id: 4,
  //   position: 'relative',
  // },
]

const ProjectsBlock = () => {
  const [windowWidth, setWindowWith] = useState()

  const handleResize = useCallback(() => {
    setWindowWith(window.innerWidth)
  }, [])

  const mobileWidth = windowWidth && windowWidth < 769
  const windowCheck = typeof window !== 'undefined'
  const [isActive, setIsActive] = useState(false)
  const [step, setStep] = useState(0)
  const [isChanged, setIsChanged] = useState(false)

  const block = useRef(null)
  const lastBlock = useRef(null)
  const imageBlock = useRef(null)

  const handleScroll = () => {
    const { top } = block.current.getBoundingClientRect()
    const { top: lastBlockTop } = lastBlock.current.getBoundingClientRect()
    let imageHeight = imageBlock.current.getBoundingClientRect().height
    let topHeight = (window.innerHeight - imageHeight) / 2

    if (top <= topHeight && lastBlockTop >= topHeight && !isActive) {
      setIsActive(true)
    }
    if (top > topHeight && isActive) {
      setIsActive(false)
    }
    if (lastBlockTop + 300 <= topHeight && isActive) {
      setIsActive(false)
    }

    if (step !== 0 && isActive && top > -500) {
      setStep(0)
    }

    if (step !== 1 && isActive && top < -500 && top >= -1600) {
      setStep(1)
    }

    if (step !== 2 && isActive && top < -1600 && top >= -2600) {
      setStep(2)
    }

    if (step !== 3 && isActive && top < -2600 && top >= -3400) {
      setStep(3)
    }

    // if (step !== 4 && isActive && top < -3700 && top >= -4000) {
    //   setStep(4)
    // }
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (mobileWidth) {
      return
    }

    windowCheck && window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  })

  useEffect(() => {
    setIsChanged(true)
    let timeout = setTimeout(() => setIsChanged(false), 700)
    return () => clearTimeout(timeout)
  }, [step])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWindowWith(window.innerWidth)
    }
  }, [])

  return (
    <div id="projects" className={styles.projectsBlock}>
      <div className="container">
        <Title className={styles.projectsBlockTitle}>Our projects</Title>
        {mappedArray.map(({ id, position }) => (
          <div
            ref={id === 0 ? block : lastBlock}
            style={{ position: position && position }}
            className={styles.projectsBlockWrapper}
            key={id}
          >
            {typeof window !== 'undefined' && window.innerWidth < 769 ? (
              <Link to={imageArray[id].link}>
                <div
                  className={
                    isActive && !mobileWidth
                      ? `${styles.projectsBlockImage} ${styles.projectsBlockImageActive} }`
                      : styles.projectsBlockImage
                  }
                  style={{
                    backgroundColor: `${
                      !(windowCheck && mobileWidth)
                        ? imageArray[step].color
                        : imageArray[id].color
                    }`,
                  }}
                  ref={imageBlock}
                >
                  <img
                    className={
                      isChanged ? styles.projectsBlockImageChanged : null
                    }
                    src={
                      mobileWidth ? imageArray[id].img : imageArray[step].img
                    }
                    alt="project image"
                    loading="lazy"
                  />
                </div>
              </Link>
            ) : (
              <div
                className={
                  isActive && !mobileWidth
                    ? `${styles.projectsBlockImage} ${styles.projectsBlockImageActive} ${imageArray[step].contentRight && styles.justifyContentRight}`
                    : `${styles.projectsBlockImage} ${imageArray[step].contentRight  && styles.justifyContentRight}`
                }
                style={{
                  backgroundColor: `${
                    !(windowCheck && mobileWidth)
                      ? imageArray[step].color
                      : imageArray[id].color
                  }`,
                }}
                ref={imageBlock}
              >
                <img
                  className={
                    isChanged ? styles.projectsBlockImageChanged : null
                  }
                  src={mobileWidth ? imageArray[id].img : imageArray[step].img}
                  alt="project image"
                  loading="lazy"
                />
              </div>
            )}
            <div
              className={
                isActive && !mobileWidth
                  ? `${styles.projectsBlockForImage} ${styles.projectsBlockForImageActive}`
                  : styles.projectsBlockForImage
              }
            />
            <div className={styles.projectsBlockInfo}>
              <span>
                {windowCheck && mobileWidth
                ? imageArray[id].title
                : imageArray[step].title}
              </span>
              <h3>
                {windowCheck && mobileWidth
                  ? imageArray[id].text
                  : imageArray[step].text}
              </h3>
              <p className={styles.projectsBlockSubtitle}>
                {windowCheck && mobileWidth
                  ? imageArray[id].chips
                  : imageArray[step].chips}
              </p>
              <Link to={windowCheck && mobileWidth
                ? imageArray[id].link
                : imageArray[step].link}
              >
                <MainButton>
                  {windowCheck && mobileWidth
                  ? imageArray[id].linkText
                  : imageArray[step].linkText}
                </MainButton>
              </Link>
            </div>
            <div className={styles.projectsBlockInfoMini}>
              <span>
                {windowCheck && mobileWidth
                  ? imageArray[id].title
                  : imageArray[step].title}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProjectsBlock
