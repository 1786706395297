import React from 'react'
import Slider from 'react-slick'
import { Link } from 'gatsby'

import Title from '../../sharedComponents/Title/Title'

import * as styles from './styles.module.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import icon1 from '../../../assets/images/svg/solutionBlock/solution block icon 1.svg'
import icon2 from '../../../assets/images/svg/solutionBlock/solution block icon 2.svg'
import icon3 from '../../../assets/images/svg/solutionBlock/solution block icon 3.svg'
import icon4 from '../../../assets/images/svg/solutionBlock/solution block icon 4.svg'

const cardsArr = [
  {
    id: 1,
    src: icon1,
    title: 'Dedicated teams',
    text:
      'Suitable for enterprise, new product offerings to fill space and a few other words',
    link: '/services/',
  },
  {
    id: 2,
    src: icon2,
    title: 'Team transfer',
    text: 'Small medium business, expanding teams and accelerating development',
    link: '/services/#team-transfer',
  },
  {
    id: 3,
    src: icon3,
    title: 'Full-cycle development',
    text: 'Any size business that needs technical implementation of a project',
    link: '/services/#full-cycle-dev',
  },
  {
    id: 4,
    src: icon4,
    title: 'Full-product development',
    text: 'Startups, owners of an idea who need help implementing it',
    link: '/services/#full-product-dev',
  },
]

const CustomPaging = () => <span className={styles.solutionBlockDot} />

const settings = {
  dots: true,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  arrows: false,
  customPaging: CustomPaging,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const Card = ({ src, title, text, link }) => (
  <Link to={link} className={styles.solutionBlockCard}>
    <img
      src={src}
      alt={`Justice-It ${title} icon`}
      loading='lazy'
    />
    <h3>{title}</h3>
    <p>{text}</p>
  </Link>
)

const SolutionBlock = () => (
  <div id="services" className={styles.solutionBlock}>
    <div className={`${styles.solutionBlockContainer} container`}>
      <div className={styles.solutionBlockText}>
        <p className={styles.solutionBlockSubtitle}>What we do</p>
        <Title className={styles.solutionBlockTitle}>
          Perfect <span>Solution</span> for your business
        </Title>
        <Link
          className={`${styles.button} ${styles.buttonMain} `}
          to="/services/"
        >
          View services
        </Link>
      </div>
      <div className={styles.solutionBlockFlex}>
        {cardsArr.map(({ id, ...rest }) =>
           <Card key={id} {...rest} />
        )}
      </div>
      <Slider className={styles.solutionBlockSlider} {...settings}>
        {cardsArr.map(({ id, ...rest }) =>
          <Card key={id} {...rest} />
        )}
      </Slider>
    </div>
  </div>
)

export default SolutionBlock
