import React from 'react'

import Seo from '../components/Seo'
import MainPage from '../components/mainComponents/MainPage'

import mainImage from '../assets/images/jpg/seo/Main page-min.jpg'

const IndexPage = () => (
  <>
    <Seo
      title="Justice | Web development company"
      description="Justice is an IT company that develops web and mobile applications in FinTech. FinTech Development Experts. Application development, IT - service, website development."
      htmlAttributes={{
        lang: 'en',
      }}
      url="https://www.it-justice.com/"
      keywords={[
        'Application development',
        'it-consulting',
        'it services',
        'website development',
        'develop a website',
        'web company',
      ]}
      image={mainImage}
    />
    <MainPage />
  </>
)

export default IndexPage
