// extracted by mini-css-extract-plugin
export var customersBlock = "styles-module--customers-block--vqM3O";
export var customersBlockContainer = "styles-module--customers-block-container--Q58aa";
export var customersBlockText = "styles-module--customers-block-text--GEZkJ";
export var customersBlockTextButtons = "styles-module--customers-block-text-buttons--8KHFD";
export var customersBlockTitle = "styles-module--customers-block-title--8ckAQ";
export var customersBlockSubtitle = "styles-module--customers-block-subtitle--Tcsiq";
export var customersBlockButtons = "styles-module--customers-block-buttons--9u+RH";
export var customersBlockCards = "styles-module--customers-block-cards--9LAMP";
export var customersBlockCard = "styles-module--customers-block-card--9zrJ2";
export var customersBlockCardBottom = "styles-module--customers-block-card-bottom--9L5z3";
export var customersBlockCardUser = "styles-module--customers-block-card-user--UtbuO";