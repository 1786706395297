import React from 'react'

import Title from '../../sharedComponents/Title/Title'

import * as styles from './styles.module.scss'

import clutch from '../../../assets/images/png/ourAward/clutch.png'

const OurAwards = () => (
  <div className={styles.awardsBlock}>
    <div className='container'>
      <Title className={styles.awardsBlockTitle}>Our Awards</Title>
      <div className={styles.awardsBlockWrapper}>
        <a
          target='_blank'
          href='https://www.goodfirms.co/company/justice-it'
        >
          <div className={styles.awardsBlockItem}>
            <img
              src='https://assets.goodfirms.co/badges/color-badge/top-website-development-companies.svg'
              alt='GoodFirms Badge'
              loading='lazy'
            />
          </div>
        </a>
        <a
          target='_blank'
          href='https://www.goodfirms.co/company/justice-it'
        >
          <div className={styles.awardsBlockItem}>
            <img
              src='https://assets.goodfirms.co/badges/normal-badge/it-services.svg'
              alt='GoodFirms Badge'
              loading='lazy'
            />
          </div>
        </a>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://clutch.co/profile/justice-it#summary'
        >
          <div className={styles.awardsBlockItem}>
            <img
              src={clutch}
              alt='clutch'
              loading='lazy'
            />
          </div>
        </a>
      </div>
    </div>
  </div>
)

export default OurAwards
