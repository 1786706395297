import React, {
  useCallback,
  useEffect,
  useState,
} from 'react'
import { Link } from 'gatsby'

import ContactUs from './ContactUs'
import Sphere from './sphere'

import * as styles from './styles.module.scss'

import sphere from '../../../assets/images/png/sphere.png'

const MainBlock = () => {
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 })
  const [isPlay, setIsPlay] = useState(false)
  const [counter, setCounter] = useState(0)

  const onMouseMove = useCallback((e) => {
    e.preventDefault()
    const { screenX, screenY } = e
    if (coordinates.x !== screenX || coordinates.y !== screenY) {
      setCoordinates({ x: screenX, y: screenY })
      setIsPlay(true)
      setCounter(1)
    }
  }, [])

  const onMouseOver = useCallback((e) => {
    e.preventDefault()
    setCounter(0)
    setCoordinates({ x: 0, y: 0 })
  }, [])

  const timeoutHandler = useCallback((counter) => {
    counter && setIsPlay(false)
  },[counter])

  useEffect(() => {
    setCounter(0)
    counter && setTimeout(() => timeoutHandler(counter), 500)
  }, [counter])

  return (
    <div
      className={styles.mainBlock}
      onMouseMove={onMouseMove}
      onMouseOver={onMouseOver}
    >
      <div className={styles.mainBlockImageTop} />
      <div className={styles.mainBlockPlusTop} />
      <div className={`${styles.mainBlockContainer} container`}>
        <div className={styles.mainBlockWrapper}>
          <h1 className={styles.mainBlockTitle}>
            <span>FinTech.</span>
            <br />
            <span>Development {isPlay}</span>
            <br />
            <span>Experts </span>
            <br />
          </h1>
          <p className={styles.mainBlockSubtitle}>
            Our clients solve high-level tasks, while we take full
            responsibility for development process
          </p>
          <Link to='/#contact-us' className={styles.mainBlockContactLink}>
            <ContactUs />
          </Link>
        </div>
        <div className={styles.mainBlockSphere}>
          <img
            src={sphere}
            alt='sphere'
            loading='eager'
          />
        </div>
        <Sphere isPlay={isPlay} coordinates={coordinates} />
      </div>
      <div className={styles.mainBlockImageBottom} />
      <div className={styles.mainBlockPlusBottom} />
    </div>
  )
}

export default MainBlock
