import React from 'react'

import Title from '../../sharedComponents/Title/Title'

import * as styles from './styles.module.scss'

import logo1 from '../../../assets/images/svg/partnersBlock/Logo 1.svg'
import logo2 from '../../../assets/images/svg/partnersBlock/Logo 2.svg'
import logo3 from '../../../assets/images/svg/partnersBlock/Logo 3.svg'
import logo4 from '../../../assets/images/svg/partnersBlock/Logo 4.svg'
import logo5 from '../../../assets/images/svg/partnersBlock/Logo 5.svg'
import logo6 from '../../../assets/images/svg/partnersBlock/Logo 6.svg'
import logo7 from '../../../assets/images/svg/partnersBlock/Logo 7.svg'
import logo8 from '../../../assets/images/svg/partnersBlock/Logo 8.svg'
import logo9 from '../../../assets/images/svg/partnersBlock/Logo 9.svg'
import logo10 from '../../../assets/images/svg/partnersBlock/Logo 10.svg'

const cardsArr = [
  {
    id: 1,
    src: logo1,
  },
  {
    id: 2,
    src: logo2,
  },
  {
    id: 3,
    src: logo3,
  },
  {
    id: 4,
    src: logo4,
  },
  {
    id: 5,
    src: logo5,
  },
  {
    id: 6,
    src: logo6,
  },
  {
    id: 7,
    src: logo7,
  },
  {
    id: 8,
    src: logo8,
  },
  {
    id: 9,
    src: logo9,
  },
  {
    id: 10,
    src: logo10,
  },
]

const Card = ({ src }) => (
  <div className={styles.partnersBlockCard}>
    <img
      src={src}
      alt="Justice-It partners logo"
      loading="lazy"
    />
  </div>
)

const PartnersBlock = () => (
  <div className={styles.partnersBlock}>
    <div className="container">
      <Title className={styles.partnersBlockTitle}>Our partners </Title>
      <div className={styles.partnersBlockWrapper}>
        <div className={styles.partnersBlockCards}>
          {cardsArr.map(({ id, src }) => (
            <Card key={id} src={src} />
          ))}
        </div>
      </div>
    </div>
  </div>
)

export default PartnersBlock
