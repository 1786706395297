import project1 from '../../../assets/images/png/companyBlock/project1.png'
import project2 from '../../../assets/images/png/companyBlock/project2.png'
import neweraMainImage from '../../../assets/images/png/newera-energyBlock/newera-main.png'
import project4 from '../../../assets/images/png/companyBlock/project4.png'

export const cardsArrTop = [
	{
		id: 1,
		type: 'My budget',
		title: 'Affordable, market-leading online cashier.',
		src: project1,
		link: '/cases/my-budget/',
	},
	{
		id: 2,
		type: 'Sushi Gang',
		title: 'Сonvenient and profitable selling store',
		src: project2,
		link: '/cases/sushi-gang/',
	},
]

export const cardsArrBottom = [
	{
		id: 1,
		type: 'NewEra Energy',
		title: 'Easy-to-use assistant system for energy saving',
		src: neweraMainImage,
		link: '/cases/newera-energy/',
	},
	{
		id: 2,
		type: 'A-Manage',
		title: "Control your company's money in a clear system",
		src: project4,
		link: '/cases/a-manage/',
	},
]
