import React from 'react'

import * as styles from './styles.module.scss'

const cardsArr = [
  {
    id: 1,
    num: 35,
    title: 'Specialists',
    text:
      'There are more than 25 specialists in our team, the main value of our company is people. We value and respect everyone, invest various resources and develop together with our employees. Our current goals are continuous development and expansion. Together - we are a force!',
  },
  {
    id: 2,
    num: 50,
    title: 'Events held this year',
    text:
      'We never stand still and are constantly developing. Inside our company, there are masterclasses from employees to improve their qualifications, various seminars and offsite conferences, as well as team-building programs - tennis on weekends and Justice Friday with games and pizza!',
  },
  {
    id: 3,
    num: 25,
    title: 'Training-events',
    text:
      'Our employees regularly take part in various trainings, where they are given the opportunity to improve their professional skills.',
  },
  {
    id: 4,
    num: 15,
    title: 'Сompleted FinTech',
    text:
      'Our main specialization is the FinTech and E-commerce industries. And today we can say with confidence about 15 successfully completed projects in this area.',
  },
  {
    id: 5,
    num: 20,
    title: 'Kg of scrap paper',
    text:
      '20kg of scrap paper and more than 100 PET bottles were recyclables. One of the main ideologies of our company is eco-responsibility. Our offices are equipped with special containers for sorting waste. Our employees share our views and help to make our planet cleaner!',
  },
  {
    id: 6,
    num: 2,
    title: '“Green” promotions',
    text:
      '2 “green” promotions were held in cooperation with coffee houses. Our company has a successful experience of collaboration with coffee shops - green promotion. The action is aimed at raising peoples awareness in order to take care of the environment.',
  },
]

const Card = ({ num, title, text }) => (
  <div className={styles.companyBlockCard}>
    <div
      style={{ paddingLeft: `${num === 2 && '25px'}` }}
      className={styles.companyBlockNum}
    >
      {num}
      <span>{num !== 2 && '+'}</span>
    </div>
    <h3>{title}</h3>
    <p>{text}</p>
  </div>
)

const CompanyBlock = () => (
  <div id="facts" className={styles.companyBlock}>
    <div className={`${styles.companyBlockContainer} container`}>
      <h2>Company Facts</h2>
      <div className={styles.companyBlockCards}>
        {cardsArr.map(({ id, ...obj }) =>
          <Card key={id} {...obj} />
        )}
      </div>
    </div>
  </div>
)

export default CompanyBlock
