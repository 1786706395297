// extracted by mini-css-extract-plugin
export var mainBlock = "styles-module--main-block--W0TEJ";
export var mainBlockWrapper = "styles-module--main-block-wrapper--PafG3";
export var mainBlockContainer = "styles-module--main-block-container--QKYPA";
export var mainBlockImageTop = "styles-module--main-block-image-top--to8cu";
export var mainBlockPlusTop = "styles-module--main-block-plus-top--Z6SDD";
export var mainBlockTitle = "styles-module--main-block-title--9D2mi";
export var mainBlockSubtitle = "styles-module--main-block-subtitle--kiHdY";
export var mainBlockContactLink = "styles-module--main-block-contact-link--tPl9Z";
export var mainBlockImageBottom = "styles-module--main-block-image-bottom--z6Z3L";
export var mainBlockPlusBottom = "styles-module--main-block-plus-bottom--3-682";
export var mainBlockSphere = "styles-module--main-block-sphere--mrhSx";
export var solutionBlock = "styles-module--solution-block--Hxq0l";
export var solutionBlockContainer = "styles-module--solution-block-container--qeLGZ";
export var solutionBlockText = "styles-module--solution-block-text--Pc6cL";
export var solutionBlockSubtitle = "styles-module--solution-block-subtitle--JvdGJ";
export var solutionBlockTitle = "styles-module--solution-block-title--P1113";
export var solutionBlockFlex = "styles-module--solution-block-flex--c87gx";
export var solutionBlockCard = "styles-module--solution-block-card--++pP7";
export var solutionBlockSlider = "styles-module--solution-block-slider--jzsFr";
export var solutionBlockDot = "styles-module--solution-block-dot--H2TNg";
export var button = "styles-module--button--mVsv9";
export var buttonFlared = "styles-module--button-flared--zp8wF";
export var buttonMain = "styles-module--button-main--rFEmd";
export var buttonLink = "styles-module--button-link--8UwRf";
export var buttonArrow = "styles-module--button-arrow--UDY8P";
export var buttonTransparent = "styles-module--button-transparent--dCBmv";
export var buttonGray = "styles-module--button-gray--siR+d";
export var slickDots = "styles-module--slick-dots--frnv0";