import React from 'react'

import Title from '../../sharedComponents/Title/Title'
import CaseBlockCards from './CaseBlockCards'
import { cardsArrBottom, cardsArrTop } from './CaseBlock.constants'

import * as styles from './styles.module.scss'

const CaseBlock = () => (
	<div id='case' className={styles.caseBlock}>
		<div className='container'>
			<Title className={styles.caseBlockTitle}>
				Case Studies
			</Title>
			<CaseBlockCards cardsArr={cardsArrTop} active={1}/>
			<CaseBlockCards cardsArr={cardsArrBottom} active={2} />
		</div>
	</div>
)

export default CaseBlock
